


/* For primary color */
.primary-color {
    background-color: #444651;  /* Adjust this color code to use your desired primary color */
    color: white;
  }
  
  /* For secondary color */
  .secondary-color {
    background-color: #E0A80F;  /* Adjust this color code to use your desired secondary color */
    color: white;
  }
  
  /* For modern button UI */
  .btn-modern {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 25px;  
    border: none;
    transition: all 0.5s;
  }
  
  .btn-modern:hover {
    transform: scale(1.02);
    background-color: #adada9;
  }
  
  /* For modern looking elements - adjust as necessary for your desired look */
  .element-modern {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

}

.element-modern:hover {
  transform: scale(1.02);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
}

/* For primary and secondary text colors */
.primary-text-color {
  color: #007BFF;  /* Adjust this color code to use your desired primary color for text */
}

.secondary-text-color {
  color: #6C757D;  /* Adjust this color code to use your desired secondary color for text */
}

.active {
    background-color: #0056b3;
    color: white;
  }

  .same-width-button {
    width: 80%;  /* Set the width to whatever you like */
  }

  .button-spacing {
    margin-top: 10px;  /* Add space at the top */
    margin-bottom: 10px;  /* Add space at the bottom */
}
.logo-image-size {
    width: 200px;
    height: auto;
}

.bodyBackgrond {
  background-color: #E1E1E3;
}